
import ModalMixin from "@/components/modal/ModalMixin.vue";
import { ICandidateDB } from "@/types";
import { Component, Prop } from "vue-property-decorator";

@Component({
    name: "CandidateModalMixin",
})
export default class CandidateModalCommandMixin extends ModalMixin {
    name: string = "";
    error: any = null;

    @Prop() candidate!: ICandidateDB | any;

    get candidateFullName() {
        return `${this.candidate.firstname || ""} ${this.candidate.lastname || ""}`;
    }

    get candidatePicture() {
        return this.candidate.picture;
    }
}

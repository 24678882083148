
import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import VModel from "@/mixins/VModel.vue";
import ListAutocompleteSelect from "@/components/ListAutocompleteSelect.vue";
import Row from "@/components/grid/Row.vue";
import FormContainer from "@/components/form/FormContainer.vue";
import PicturePreview from "@/components/PicturePreview.vue";
import InputGmapAutocomplete from "@/components/form/inputGmapAutocomplete.vue";
import { formattedAddress } from "@/helpers/commons";
import { StoreAction, ICandidateDB, ICountry, LabelValue } from "@/types";
import { Action, Getter } from "vuex-class";
import InterimJobs from "@/components/InterimJobs.vue";
import Field from "@/components/form/Field.vue";
import SelectAutocomplete from "@/components/SelectAutocomplete.vue";
import PdfManager from "@/components/PdfManager.vue";
import { parsePhoneNumber } from "libphonenumber-js";
import { showModal } from "@/helpers/callables";
import ParseWait from "@/components/ats/modal/ParseWait.vue";
import moment from "moment";
import { useWorkerAddIdentificateStore } from "@/store/WorkerAddIdentificate";
import GeocodeInput from "@/components/form/v3/GeocodeInput.vue";

@Component({
    name: "CandidateForm",
    components: {
        InterimJobs,
        ListAutocompleteSelect,
        Row,
        FormContainer,
        PicturePreview,
        InputGmapAutocomplete,
        Field,
        SelectAutocomplete,
        PdfManager,
        ParseWait,
        GeocodeInput,
    },
})
export default class CandidateForm extends mixins(VModel) {
    @Prop() errors!: any;
    @Prop(Boolean) full!: boolean;

    // jobOffers: any = [];
    loading = false;
    origin = "";
    showParseWait: boolean = false;
    extensionAccept: string[] = [".pdf", ".jpeg", ".jpg"];

    candidate: ICandidateDB | any;

    // @Action('actions/loadJobOffers') loadJobOffers!: StoreAction;
    @Action("actions/analyseCandidate") analyseCandidate!: StoreAction;
    @Getter("getBirthCountriesOptions") getBirthCountriesOptions!: Array<ICountry>;
    @Getter("applicationOrigin") applicationOrigin!: LabelValue[];

    // get jobOfferOptions() {
    //     return this.jobOffers && ([
    //         ...this.jobOffers.map((jobOffer: any) => {
    //             return {
    //                 label: jobOffer.name,
    //                 value: jobOffer._id,
    //                 selected: jobOffer._id === this.innerValue.jobOfferId
    //             };
    //         })
    //     ]) || [];
    // }

    openModal() {
        this.showParseWait = true;
        showModal.call(this, "ParseWait");
    }

    closeModal() {
        this.showParseWait = false;
        this.$modal.hide("ParseWait");
    }

    get candidateAddress() {
        return formattedAddress(this.innerValue.address);
    }

    get isCreate() {
        return !this.innerValue._id;
    }

    get hasLocation() {
        return this.innerValue.address?.location?.length === 2;
    }

    originChanged(origin: string) {
        this.innerValue.origin = origin;
    }

    placeChanged(place: any) {
        if (this.innerValue.address) {
            this.innerValue.address.name = "main";
            this.innerValue.address.street = [place.street_number, place.street]
                .filter((s) => s)
                .join(" ")
                .trim();
            this.innerValue.address.complement = place.complement || this.innerValue.address.complement;
            this.innerValue.address.city = place.locality;
            this.innerValue.address.zip = place.zip;
            this.innerValue.address.countryCode = place.countryCode;
            this.innerValue.address.inseeCode = place.inseeCode;
            this.innerValue.address.location = [place.longitude, place.latitude];
        }
    }

    get countriesOptions() {
        return [
            { label: "-", value: "" },
            ...this.getBirthCountriesOptions.map((n) => {
                return { label: n.name, value: n.code };
            }),
        ];
    }

    get sourceRenseigne() {
        if (this.innerValue.source !== null) {
            return true;
        }
        if (this.innerValue.source !== undefined) {
            return true;
        }
        return true;
    }

    async init() {
        try {
            // let result
            // result = await this.loadJobOffers();

            // this.jobOffers = result.jobOffers;

            const hasOrigin = this.applicationOrigin.find((o) => o.value === this.innerValue.origin);
            if (hasOrigin) this.origin = this.innerValue.origin;
            else this.origin = "";

            const store = useWorkerAddIdentificateStore();

            this.innerValue.firstname = store.findWorkerForm.firstname.value;
            this.innerValue.lastname = store.findWorkerForm.lastname.value;
            this.innerValue.email = store.findWorkerForm.email.value;
            this.innerValue.phone = store.findWorkerForm.phone.value;
            this.innerValue.birthDate = store.findWorkerForm.birthDate.value;
            this.innerValue.cv.path = store.cv;
            this.innerValue.dataAnalysis = store.cvParsing;

            store.$reset();
            this.innerValue.jobs = [];
        } catch (e) {
            console.error(e);
        }
    }

    async onDropedCV(cv: any) {
        try {
            this.openModal();
            const parsedCandidate = await this.analyseCandidate({
                data: {
                    cv: cv,
                },
            });

            this.innerValue.dataAnalysis = parsedCandidate.raw;

            this.innerValue.firstname = parsedCandidate.parsed.firstname?.value || "";
            this.innerValue.lastname = parsedCandidate.parsed.lastname?.value || "";
            this.innerValue.email = parsedCandidate.parsed.email?.value || "";
            this.innerValue.picture = parsedCandidate.parsed.picture?.value || "";
            this.innerValue.phone = parsedCandidate.parsed.phone?.value
                ? parsePhoneNumber(parsedCandidate.parsed.phone?.value, "FR").formatNational()
                : "";
            (this.innerValue.address = {
                name: "",
                street: parsedCandidate.parsed.address.street?.value || "",
                complement: "",
                city: parsedCandidate.parsed.address.city?.value || "",
                citySlug: "",
                zip: parsedCandidate.parsed.address.zip?.value || "",
                countryCode: "",
            }),
                parsedCandidate.parsed.address.value;
            this.innerValue.keywords = parsedCandidate.parsed.keywords?.value;
            this.innerValue.birthDate = moment(parsedCandidate.parsed.birthDate.value).format("DD/MM/YYYY");

            this.innerValue.cv.path = cv;

            this.$forceUpdate();
            this.closeModal();
        } catch (e) {
            console.log(e);
            this.closeModal();
        }
    }

    beforeMount() {
        this.init();
    }
}
